<template>
  <div class="modal fade" data-backdrop="static" data-keyboard="false" id="newNcrObs" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <form class="modal-content" @submit.prevent="submit">
        <div class="modal-header">
          <div class="modal-title w-100 text-center">
            <h5 class="font-weight-bold">ADD NEW {{ recordType?.toUpperCase() }}</h5>
          </div>
        </div>
        <div class="modal-body" style="height: 60vh; overflow-y: auto">

          <!--          FOR NCR TYPES  -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Type</label>
            <div class="col-sm-9">
              <span class="form-control form-control-sm" style="border: none">
                {{ recordType?.toUpperCase() }}
              </span>
            </div>
          </div>

<!--          FOR NCR/OBS Description  -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">NCR/OBS Description</label>
            <div class="col-sm-9">
              <vue-editor class="my-2" :editor-toolbar="toolbar" v-model="details.description">
              </vue-editor>
            </div>
          </div>
<!--          FOR Root Causes  -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Root Causes</label>
            <div class="col-sm-9">
              <root-cause-selector
                @done="setNewRootCauses"
                :items="details.root_cause_ids"
              />
            </div>
          </div>
<!--          FOR Training Need -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Training Need</label>
            <div class="col-sm-9">
              <input class="ml-0 form-check-input checkbox-input" type="checkbox" value="" id="training_need" :checked="details.training_need === 1" @click="details.training_need = setOneOrZero(details.training_need)">
            </div>
          </div>

          <!--  FOR Change of Procedures -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Change of Procedures</label>
            <div class="col-sm-9">
              <input class="ml-0 form-check-input checkbox-input" type="checkbox" value="" id="training_need" :checked="details.change_of_procedures === 1" @click="details.change_of_procedures = setOneOrZero(details.change_of_procedures)">
            </div>
          </div>

          <!-- FOR Prevention of Recurrence ashore -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Prevention of Recurrence ashore</label>
            <div class="col-sm-9">
              <vue-editor v-model="details.recurrence_ashore" class="my-2" :editor-toolbar="toolbar">
              </vue-editor>
            </div>
          </div>

          <!-- FOR Prevention of Recurrence onsite -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Prevention of Recurrence onsite</label>
            <div class="col-sm-9">
              <vue-editor v-model="details.recurrence_on_site" class="my-2" :editor-toolbar="toolbar">
              </vue-editor>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" ref="NewUser_close" @click.prevent="closeModal">Close</button>
<!--          <button type="submit" class="btn btn-sm btn-primary" :disabled="noSelectedAuditTypeOne" :class="noSelectedAuditTypeOne ? 'e-btn-blue-disabled' : ''">Create New Record</button>-->
          <button type="submit" class="btn btn-sm btn-primary">Create New Record</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import NonConformityMixin from "@/mixins/NonConformityMixin";
import {mapActions} from "vuex";
import {AlertService} from "@/services/AlertService";
import RootCauseSelector from "@/components/modules/ia/vessel/nonConformity/details/RootCauseSelector";
import requestLoaderMixin from "@/mixins/requestLoaderMixin";

export default {
  name : 'AddNonConformity',
  mixins : [NonConformityMixin, requestLoaderMixin],
  components : {RootCauseSelector},
  props : {
    recordType : {
      type : String,
      default : 'ncr'
    }
  },
  methods: {
    ...mapActions([
      'createNonConformities'
    ]),
    closeModal() {
      this.details = this.setDetailInitialValues();
      $('#newNcrObs').modal('hide');
      this.$emit('close');
    },
    async submit() {
      this.showLoaderRequest('SAVING NON-CONFORMITY, PLEASE WAIT...');
      this.details.ncr_type = this.recordType;
      let param = Object.assign({}, this.details);
      param.id = this.selectedAuditRecord[this.$route.params.type].id;
      param.type = this.$route.params.type;
      const createResponse = await this.createNonConformities(param);
      if (createResponse.result === false) {
        return AlertService.errorAlert(createResponse.message, 'ADDING OF NCR/OBS DETAIL');
      }
      await this.getUpdatedNonConformity();
      this.hideLoaderRequest();
      AlertService.successAlert('Created Successfully', 'ADDING OF NEW NCR/OBS DETAIL');
      this.closeModal();
    },
    setNewRootCauses(newValue) {
      this.details.root_cause_ids = newValue;
    }
  }
}
</script>

<style scoped>

</style>
