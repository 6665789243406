<template>
  <div>
    <div class="card p-3 mb-3 shadow-sm">
      <div @click="showNcrLists = !showNcrLists" class="card rounded-0 shadow-sm cursor-pointer no-select">
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div class="pl-3 pt-2 font-weight-bold mr-2">
              <h5 class="e-text-red">
                Non-Conformity
              </h5>
            </div>
          </div>
          <div class="pr-3 pt-2" v-if="internal_audit_reports[$route.params.type]?.has_ncr_obs === 1 && (user.id === selectedAuditRecord.created_by_id || isAllowed('vessel-ia.appointment.non-conformity.create'))">
            <button v-if="showNcrLists" class="e-btn e-btn-green mb-2 px-4 e-text-white mx-2" @click.stop="openModal('ncr')">
              <font-awesome-icon icon="plus" class="mr-2"/>
              Add NCR
            </button>
            <font-awesome-icon icon="bars" style="font-size: 20px; position: relative; top: 4px"/>
          </div>
        </div>
      </div>
      <template v-if="showNcrLists">
        <table class="table table-sm table-bordered text-center">
          <thead>
          <tr>
            <th>#</th>
            <th style="width: 30%">Non-Conformity</th>
            <th style="width: 25%">Root Causes</th>
            <th>Training Need</th>
            <th>Change of Procedures</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody v-if="loading">
          <tr>
            <td colspan="100%">
              <app-loading></app-loading>
            </td>
          </tr>
          </tbody>
          <draggable v-else tag="tbody" v-model="nonConformityType[$route.params.type]" @end="onEnd">
            <tr
              data-value="ncr"
              class="list-data cursor-grab text-uppercase no-select"
              v-for="(nonConformity, index) in nonConformityType[$route.params.type]"
              :class="[
              index === activeNcrIndex && $route.params.ncr_type === 'ncr' ? 'selected-row' : '',
              index === mouseHoldActiveRowIndex.ncr ? 'mouse-active-row' : ''
            ]"
              :key="'index_' + index + '_data_for_' + $route.params.ncr_type"
              @dblclick="openOrChangeIndex(nonConformity.id, nonConformity.type, index)"
              @mousedown="setActiveBackground('add', index, 'ncr')"
              @mouseup="setActiveBackground('remove', index, 'ncr')"
            >
              <td class="align-middle"> {{ index + 1 }} </td>
              <td class="align-middle text-left" style="word-break: break-word"> {{ nonConformity.description | stripHtmlTags }} </td>
              <td class="align-middle pt-1 pb-1" style="padding: 0">
                <div class="text-left pl-3">
                  <template v-for="cause in groupByParentCause(nonConformity.root_cause)">
                    <span class="font-weight-bold">{{ cause.name }}</span>
                    <div class="pl-3" v-for="childCauses in cause.selected_causes">
                      <font-awesome-icon
                        style="font-size: 5px; margin-bottom: 2px"
                        icon="circle"/>
                      {{ childCauses.name }}
                    </div>
                  </template>
                </div>
              </td>
              <td class="align-middle"> {{ nonConformity.training_need === 1 ? 'YES' : 'NO' }} </td>
              <td class="align-middle"> {{ nonConformity.change_of_procedures === 1 ? 'YES' : 'NO' }} </td>
              <td class="align-middle">
                <div class="dropdown dropright">
                  <button
                    id="actionDropdown"
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="mr-2 btn btn-sm btn-secondary force-white-all"
                    data-toggle="dropdown"
                  >
                    <font-awesome-icon icon="cogs"/>
                  </button>
                  <div aria-labelledby="actionDropdown" class="dropdown-menu py-0" style="min-height: 50px">
                    <button class="dropdown-item text-secondary font-weight-bolder"
                            v-if="isAllowed('vessel-ia.appointment.non-conformity.view')"
                            @click="openOrChangeIndex(nonConformity.id, nonConformity.type, index)">
                      <font-awesome-icon icon="eye"/>
                      View
                    </button>
                    <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                            v-if="user.id === selectedAuditRecord.created_by_id || isAllowed('vessel-ia.appointment.non-conformity.delete')"
                            @click="deleteSelected(nonConformity.id)"
                    >
                      <font-awesome-icon icon="trash" class="force-danger-all"/>
                      Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </draggable>
        </table>
      </template>

      <div @click="showObsLists = !showObsLists" class="card rounded-0 shadow-sm cursor-pointer no-select" >
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div class="pl-3 pt-2 font-weight-bold mr-2">
              <h5 class="e-text-red">
                Observation Note
              </h5>
            </div>
          </div>
          <div class="pr-3 pt-2" v-if="internal_audit_reports[$route.params.type]?.has_ncr_obs === 1 && (user.id === selectedAuditRecord.created_by_id || isAllowed('vessel-ia.appointment.non-conformity.create'))">
            <button v-if="showObsLists" class="e-btn e-btn-green mb-2 px-4 e-text-white mx-2" data-toggle="modal" data-target="#newNcrObs" @click.stop="openModal('obs')">
              <font-awesome-icon icon="plus" class="mr-2"/>
              Add OBS
            </button>
            <font-awesome-icon icon="bars" style="font-size: 20px; position: relative; top: 4px"/>
          </div>
        </div>
      </div>
      <template v-if="showObsLists">
        <table class="table table-sm table-bordered text-center">
          <thead>
          <tr>
            <th>#</th>
            <th style="width: 30%">Observation Note</th>
            <th style="width: 25%">Root Causes</th>
            <th>Training Need</th>
            <th>Change of Procedures</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody v-if="loading">
          <tr>
            <td colspan="100%">
              <app-loading></app-loading>
            </td>
          </tr>
          </tbody>
          <draggable v-else tag="tbody" v-model="observationNoteType[$route.params.type]" @end="onEnd">
            <tr
              data-value="obs"
              class="list-data cursor-grab text-uppercase no-select"
              v-for="(nonConformity, index) in observationNoteType[$route.params.type]"
              :class="[
              index === activeNcrIndex && $route.params.ncr_type === 'obs' ? 'selected-row' : '',
              index === mouseHoldActiveRowIndex.obs ? 'mouse-active-row' : ''
            ]"
              :key="'index_' + index + '_data_for_' + $route.params.ncr_type"
              @dblclick="openOrChangeIndex(nonConformity.id, nonConformity.type, index)"
              @mousedown="setActiveBackground('add', index, 'obs')"
              @mouseup="setActiveBackground('remove', index, 'obs')"
            >
              <td class="align-middle"> {{ index + 1 }} </td>
              <td class="align-middle text-left" style="word-break: break-word"> {{ nonConformity.description | stripHtmlTags }} </td>
              <td class="align-middle pt-1 pb-1" style="padding: 0">
                <div class="text-left pl-3">
                  <template v-for="cause in groupByParentCause(nonConformity.root_cause)">
                    <span class="font-weight-bold">{{ cause.name }}</span>
                    <div class="pl-3" v-for="childCauses in cause.selected_causes">
                      <font-awesome-icon
                        style="font-size: 5px; margin-bottom: 2px"
                        icon="circle"/>
                      {{ childCauses.name }}
                    </div>
                  </template>
                </div>
              </td>
              <td class="align-middle"> {{ nonConformity.training_need === 1 ? 'YES' : 'NO' }} </td>
              <td class="align-middle"> {{ nonConformity.change_of_procedures === 1 ? 'YES' : 'NO' }} </td>
              <td class="align-middle">
                <div class="dropdown dropright">
                  <button
                    id="actionDropdown"
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="mr-2 btn btn-sm btn-secondary force-white-all"
                    data-toggle="dropdown"
                  >
                    <font-awesome-icon icon="cogs"/>
                  </button>
                  <div aria-labelledby="actionDropdown" class="dropdown-menu py-0" style="min-height: 50px">
                    <button class="dropdown-item text-secondary font-weight-bolder"
                            v-if="isAllowed('vessel-ia.appointment.non-conformity.view')"
                            @click="openOrChangeIndex(nonConformity.id, nonConformity.type, index)">
                      <font-awesome-icon icon="eye"/>
                      View
                    </button>
                    <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                            v-if="isAllowed('vessel-ia.appointment.non-conformity.delete')"
                            @click="deleteSelected(nonConformity.id)"
                    >
                      <font-awesome-icon icon="trash" class="force-danger-all"/>
                      Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </draggable>
        </table>
      </template>
    </div>

    <template v-if="$route.params.ncr_id !== 'index' && activeNcrIndex !== null && $route.params.ncr_type !== 'none'">
      <non-conformity-detail
        :key="'non_conformity_' + $route.params.ncr_id + '_' + $route.params.ncr_type + '_' + activeNcrIndex"
        :active-ncr-index="activeNcrIndex"
        :conformity-length="$route.params.ncr_type === 'ncr' ? nonConformityType[$route.params.type].length : observationNoteType[$route.params.type].length"
        @changeIndex="changeIndex"
        @close="closeDetail"
      >
      </non-conformity-detail>
    </template>

    <add-non-conformity
      :key="'add-ncr-' + modalToShowOnAdd"
      :record-type="modalToShowOnAdd"
      @close="modalToShowOnAdd = null"
    />
  </div>

</template>

<style scoped>

</style>

<script>
import nonConformityDetail from "@/components/modules/ia/vessel/nonConformity/nonConformityDetail.vue";
import AddNonConformity from "@/components/modules/ia/vessel/nonConformity/AddNonConformity.vue";
import NonConformityMixin from "@/mixins/NonConformityMixin";
import {AlertService} from "@/services/AlertService";
import {mapActions} from "vuex";
import {vueAppMixin} from "@/mixins/vueAppMixin";
import AppLoading from "@/components/elements/AppLoading.vue";
import draggable from 'vuedraggable';
import _ from 'lodash';

  export default {
    name: 'NonConformity',
    components : {AppLoading, nonConformityDetail, AddNonConformity, draggable},
    mixins: [NonConformityMixin, vueAppMixin],
    data() {
      return {
        activeNcrIndex : null,
        modalToShowOnAdd : null,
        selectedNcrType : this.$route.params.ncr_type,
        mouseHoldActiveRowIndex : {
          ncr : null,
          obs : null
        },
        recordType : 'ncr',
        showNcrLists : false,
        showObsLists : false
      }
    },
    methods : {
      ...mapActions([
        'deleteNonconformity',
        'updateOrderNumberFromList'
      ]),
      openModal(modalType) {
        this.modalToShowOnAdd = modalType;
        this.$nextTick(() => {
          $('#newNcrObs').modal('show');
        });
      },
      setActiveBackground(method, index, ncr_type) {
        if (method === 'add') {
          this.mouseHoldActiveRowIndex[ncr_type] = index;
        } else {
          this.mouseHoldActiveRowIndex[ncr_type] = null;
        }
      },
      getUpdatedOrderFromList(ncrType) {
        let newUpdatedOrderList = [];
        const data = ncrType === 'ncr' ? this.nonConformityType[this.$route.params.type] : this.observationNoteType[this.$route.params.type];

        const orderNumbers = _.sortBy(data.map(item => item.list_order));

        data.forEach((item, index) => {
          newUpdatedOrderList.push({
            id : item.id,
            prev_list_order : item.list_order,
            list_order : orderNumbers[index],
          });
        });

        return newUpdatedOrderList;
      },
      async onEnd(event) {
        const dataType = $(event.item).attr('data-value');

        if (this.activeNcrIndex !== null && this.$route.params.ncr_type === dataType) {
          if (event.oldIndex === this.activeNcrIndex) {
            this.activeNcrIndex = event.newIndex;
          } else if (event.oldIndex > this.activeNcrIndex && event.newIndex <= this.activeNcrIndex) {
            this.activeNcrIndex++;
          } else if (event.oldIndex < this.activeNcrIndex && event.newIndex >= this.activeNcrIndex) {
            this.activeNcrIndex--;
          }
        }

        this.setActiveBackground('remove', null, dataType);
        const listWithUpdatedOrder = this.getUpdatedOrderFromList(dataType);
        const updateOrderFromListResponse = await this.updateOrderNumberFromList({
          id : this.$route.params.id,
          type : this.$route.params.type,
          module : 'non_conformity',
          lists_orders : listWithUpdatedOrder
        });
        if (updateOrderFromListResponse.result === false) {
          return AlertService.errorAlert(updateOrderFromListResponse.message, 'UPDATING OF NCR/OBS ORDERS');
        }

        await this.getUpdatedNonConformity(false);
      },
      openOrChangeIndex(nonConformityId, nonConformityType, index) {
        if (this.$route.params.ncr_id === 'index' || this.$route.params.ncr_type !== nonConformityType) {
          this.viewSelected(nonConformityId, nonConformityType, index)
        } else {
          this.changeIndex(index);
        }

        this.scrollToNcrMainDetails('ncr_main_details', 100, 100);
      },
      setNcrType() {
        this.$router.replace({name: this.$route.name, params:
            {
              ncr_id : 'index',
              ncr_type : this.selectedNcrType,
            }
        });

        this.getUpdatedNonConformity();
      },
      changeIndex(newIndex) {
        this.activeNcrIndex = newIndex;
        const ncrType = this.$route.params.ncr_type;
        const data = ncrType === 'ncr' ? this.nonConformityType[this.$route.params.type] : this.observationNoteType[this.$route.params.type];
        const nonConformityId = data[newIndex].id;
        if (nonConformityId !== parseInt(this.$route.params.ncr_id)) {
          this.$router.replace({name: this.$route.name, params: {
              ncr_id: nonConformityId,
              oe_id  : 'index'
            }});
        }
      },
      viewSelected(nonConformityId, nonConformityType, activeNcrIndex) {
        this.activeNcrIndex = activeNcrIndex;
        if (nonConformityId !== parseInt(this.$route.params.ncr_id)) {
          this.$router.replace({name: 'NcrMainDetails', params: {
              ncr_id: nonConformityId,
              oe_id  : 'index',
              ncr_type : nonConformityType
          }});
        }
      },
      async deleteSelected(nonConformityId) {
        if(await AlertService.confirmDeleteAlert('Delete Non-conformity', 'Are you sure you want to delete this Non-conformity Record?', 'Delete')){
          swal.fire({
            title:'ENTER YOUR PASSWORD',
            input: 'password',
            inputAttributes: {
              autocapitalize: 'off'
            },
            confirmButtonText:'PROCEED DELETION',
            cancelButtonText: 'CANCEL',
            showCancelButton:true,
            allowOutsideClick:false,
            allowEscapeKey:false,
            allowEnterKey:false,
            confirmButtonColor:'#dc3545',
            showLoaderOnConfirm:true,
            preConfirm:async (inputValue)=> {
              let response = await this.deleteNonconformity({id: nonConformityId, password: inputValue});
              if (typeof response === 'boolean' && response) {
                return response;
              } else {
                if (response.hasOwnProperty('message') && !response.hasOwnProperty('errors')) {
                  swal.showValidationMessage(response.message);
                }
                else{
                  swal.showValidationMessage(response.errors.password.join(','));
                }
              }
            }
          })
            .then(async (result)=>{
              if(result.isConfirmed){
                await this.successAlert('NON-CONFORMITY DELETED SUCCESSFULLY','DELETE NON-CONFORMITY RECORD');
                await this.getUpdatedNonConformity();

                if (this.$route.name !== 'NonConformity') {
                  await this.$router.replace({name: 'NonConformity', params: {
                      ncr_id : 'index',
                      oe_id  : 'index'
                    }});
                }
              }
            });
        }
      },
      closeDetail() {
        this.activeNcrIndex = null;
        this.$router.replace({name: 'NonConformity', params: {
            ncr_id : 'index',
            oe_id  : 'index',
            ncr_type : 'none'
          }});
      },
    },
    async created() {
      await this.getUpdatedNonConformity();
      // this.setNcrObservationData();
      if (this.$route.params.ncr_id === 'index') {
        return;
      }

      const ncrType = this.$route.params.ncr_type;
      if (ncrType !== 'none') {
        let data = ncrType === 'ncr' ? this.nonConformityType[this.$route.params.type] : this.observationNoteType[this.$route.params.type];
        const ncrIndex = data.findIndex(ncr => ncr.id === parseInt(this.$route.params.ncr_id));
        if (ncrIndex !== -1) {
          this.activeNcrIndex = ncrIndex
        }
      }

      if (this.$route.params.ncr_id !== 'index') {
        if (this.$route.params.ncr_type === 'ncr') {
          this.showNcrLists = true;
        } else if (this.$route.params.ncr_type === 'obs') {
          this.showObsLists = true;
        }
      }
    }
  }
</script>

<style>
.no-padding-margin {
  padding: 0;
  margin: 0;
  list-style: none; /* Optional: remove bullet points */
}

.mouse-active-row {
  box-shadow : rgba(0, 0, 0, 0.5) 0px 0px 5px 5px;
}

</style>
